<template>
  <v-row>
    <v-dialog
      v-model="isDialog"
      persistent
      max-width="700px"
      transition="scale-transition"
    >
      <v-card outlined>
        <v-card-title class="mb-0">
          {{ $t("extracuricular.reason_refusal") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form ref="rejected" v-model="formRejected" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-textarea
                  v-model="modelTextArea"
                  :label="$t('extracuricular.dialog.hint_reject')"
                  single-line
                  outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class="ma-2" outlined color="primary" @click="closeDialog">
            {{ $t("app.back") }}
          </v-btn>
          <v-btn
            :disabled="!modelTextArea"
            :loading="loading"
            class="ma-2 gradient-primary"
            dark
            outlined
            @click="submit"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    isDialog: {
      type: Boolean,
      required: true
    },
    loading: Boolean
  },
  watch: {
    modelTextArea(newVal) {
      this.$emit("value", newVal);
    },
    isDialog(val) {
      if (!val) this.$refs.rejected.reset();
    }
  },
  data() {
    return {
      modelTextArea: "",
      formRejected: ""
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    submit() {
      this.$emit("save");
    }
  }
};
</script>
